type ISeperator = '.' | ' ';

interface IArgs {
  number: number;
  decimals?: number;
  seperator?: ISeperator;
}

export const currency = (args: IArgs) => {
  try {
    return parseFloat(args.number.toString())
      .toFixed(args.decimals ? args.decimals : 0)
      .replace('.', ',')
      .replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        `$1${args.seperator ? args.seperator : '.'}`
      );
  } catch (err) {
    return 0;
  }
};
